import { DefaultTheme } from "styled-components";

const defaultTheme: DefaultTheme = {
  fontSize: {
    h1: { fontSize: "2rem", lineHeight: "2.5rem" },
    h2: { fontSize: "1.5rem", lineHeight: "2rem" },
    h3: { fontSize: "1rem", lineHeight: "1.5rem" },
    h4: { fontSize: "0.875rem", lineHeight: "1.375rem" },
    h5: { fontSize: "0.75rem", lineHeight: "1rem" },
    h6: { fontSize: "0.5rem", lineHeight: "0.75rem" },
  },
  font: {
    body: "Gilroy-Regular",
    optional: "Nunito",
  },
  colors: {
    doshpalGradient:
      "linear-gradient(152.28deg, rgba(246, 202, 0, 0.94) 4.29%, #FF0000 96.19%)",
    doshpalColor: "#140D40",
    doshpalYellow: "#F6CA00",
    doshpalRed: "#FF0000",
    doshpalGreyWhite: "#FAFAFA",
    doshpalWhiteSub: "#FEFFFE",
    doshpalWhiteAlt: "#F1F1F5",
    doshpalDarkAlt: "#171725",
    doshpalPrimaryGradient:
      "linear-gradient(152.28deg, rgba(246, 202, 0, 0.94) 4.29%, #FF0000 96.19%)",
    doshpalBlackLinearGradient:
      "linear-gradient(180deg, #161626 0%, #000000 100%)",
    doshpalGreen: "#2D9E64",
    doshpalGreyishBlue: "#BFD7EA",
    doshpalDarkBlue: "#131321",
    doshpalOrange: "#FB5806",
    doshpalLightOrange: "#FFEBE6",
    doshpalWhite: "#FFFFFF",
    doshpalBlack: "#000000",
    doshpalLightPink: "#FDF3F0",
    doshpalGray: "#D1DADD",
    doshpalDarkGray: "#AAAAAA",
    doshpalDarkAsh: "#525A6E",
    doshpalLightAsh: "#E3E8EC",
    doshpalWhitishBlue: "#EDF0F4",
    doshpalGreyBlack: "#666666",
    boxShadow: "#e6e6e8",
    onActive: "#FFEBE6",
    doshpalModalBoxShadow: "0px 0px 8.85649px rgba(45, 58, 58, 0.02)",
    profileBackgroundColor:
      "linear-gradient(122.62deg,rgba(19, 19, 33, 0.1) 0%,rgba(19, 19, 33, 0) 100%)",
  },
  borderRadius: {
    sm: "0.25rem",
    md: "0.5rem",
    lg: "0.75rem",
  },
  buttonPalette: {
    secondary: {
      background: "#FFEBE6",
      color: "#FB5806",
    },
    secondaryInverse: {
      background: "transparent",
      color: "#FB5806",
    },
    primary: {
      background: "#131321",
      color: "#ffffff",
    },
    tertiary: {
      background: "#EDF0F4",
      color: "#000000",
    },
    primaryBlue: {
      background: "#4867AA",
      color: "#ffffff",
    },
    lightPrimaryBlue: {
      background: "#5DA9DD",
      color: "#ffffff",
    },
    tertiaryInverse: {
      background: "#E3E8EC",
      color: "#525A6E",
    },
    tertiaryNone: {
      background: "#201852",
      color: "#ffffff",
    },
    doshBlue: {
      background: "#140D40",
      color: "#ffffff",
    },
  },
  slider: {
    palette: {
      secondary: {
        color: "#AAAAAA",
      },
      primary: {
        color: "#131321",
      },
    },
  },
  feedBackComponent: {
    secondary: "#0B395480",
    primary: "#FAFAFA",
  },
  tooltip: {
    bottom: {
      top: "100%",
      left: "42%",
      right: "",
      bottom: "",
      borderColor: "#FEFFFE transparent transparent transparent",
    },
    bottomRight: {
      top: "100%",
      left: "70%",
      right: "",
      bottom: "",
      borderColor: "#FEFFFE transparent transparent transparent",
    },
    bottomLeft: {
      top: "100%",
      left: "15%",
      right: "",
      bottom: "",
      borderColor: "#FEFFFE transparent transparent transparent",
    },
    right: {
      top: "42% ",
      left: "100%",
      right: "",
      bottom: "",
      borderColor: "transparent transparent transparent #FEFFFE",
    },
    rightTop: {
      top: "15%",
      left: "100%",
      right: "",
      bottom: "",
      borderColor: "transparent transparent transparent #FEFFFE",
    },
    rightBottom: {
      top: "55%",
      left: "100%",
      right: "",
      bottom: "",
      borderColor: "transparent transparent transparent #FEFFFE",
    },
    left: {
      top: "",
      left: "",
      right: "100%",
      bottom: "35%",
      borderColor: "transparent #FEFFFE transparent transparent",
    },
    leftTop: {
      top: "",
      left: "",
      right: "100%",
      bottom: "55%",
      borderColor: "transparent #FEFFFE transparent transparent",
    },
    leftBottom: {
      top: "",
      left: "",
      right: "100%",
      bottom: "15%",
      borderColor: "transparent #FEFFFE transparent transparent",
    },
    top: {
      top: "",
      left: "",
      right: "40%",
      bottom: "100%",
      borderColor: "transparent transparent #FEFFFE transparent",
    },
    topRight: {
      top: "",
      left: "",
      right: "15%",
      bottom: "100%",
      borderColor: "transparent transparent #FEFFFE transparent",
    },
    topLeft: {
      top: "",
      left: "",
      right: "70%",
      bottom: "100%",
      borderColor: "transparent transparent #FEFFFE transparent",
    },
  },
};

export default defaultTheme;
