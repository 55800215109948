import React, { FC } from "react";
import { ThemeProvider } from "styled-components";
import defaultTheme from "../styles/theme";

interface ComponentProps {
}

function withTheme<T extends ComponentProps>(Component: FC<T>) {
  return (props: T) => (
    <ThemeProvider theme={defaultTheme}>
      <Component {...props} />
    </ThemeProvider>
  );
}

export { withTheme };
