import React from "react";
import GlobalStyles from "./styles/global";

import { withTheme } from "./hoc";
import Routes from "./controllers/router";
import { Toaster } from "react-hot-toast";

function App() {
  return (
    <>
      <GlobalStyles />
      <Routes />
      <Toaster position="top-right" reverseOrder={true} />
    </>
  );
}

export default withTheme(App);
