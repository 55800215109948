import React from "react";
import { Router } from "@reach/router";
import { ScrollToTop } from "../../hoc";

import {
  HomePage,
  LaunchPage,
  DoshFlex,
  DoshLyft,
  DoshInvest,
  AboutUs,
  LoanApplication,
} from "./export";

const Routes = () => {
  return (
    <Router primary={false}>
      <ScrollToTop path="/">
        <HomePage path="/" />
        <LaunchPage path="/launch-page" />
        <DoshFlex path="/doshflex" />
        <DoshLyft path="/doshlyft" />
        <DoshInvest path="/doshinvest" />
        <AboutUs path="/about-us" />
        <LoanApplication path="/apply" />
      </ScrollToTop>
    </Router>
  );
};
export default Routes;
