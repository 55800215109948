import React, { ReactElement, FC } from "react";
import { RouteComponentProps } from "@reach/router";

interface ScrollToTopProps extends RouteComponentProps {
  children: any;
}
export const ScrollToTop: FC<ScrollToTopProps> = ({
  children,
  location,
}): ReactElement => {
  React.useEffect(() => window.scrollTo(0, 0), [location?.pathname]);
  return children;
};
