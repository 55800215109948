import { lazy } from "react";

const LaunchPage = lazy(() => import("../../components/pages/LaunchPage"));
const HomePage = lazy(() => import("../../components/pages/HomePage"));
const DoshFlex = lazy(() => import("../../components/pages/Doshflex"));
const DoshLyft = lazy(() => import("../../components/pages/Doshlyft"));
const DoshInvest = lazy(() => import("../../components/pages/DoshInvest"));
const AboutUs = lazy(() => import("../../components/pages/AboutUs"));
const LoanApplication = lazy(() => import("../../components/pages/Loan"));

export {
  HomePage,
  LaunchPage,
  DoshFlex,
  AboutUs,
  DoshLyft,
  DoshInvest,
  LoanApplication,
};
